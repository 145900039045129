import { useCallback, useEffect } from 'react'

import { Loader } from '@mantine/core'

import { useParams } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { BEFORE_LOGOUT } from 'src/graphql/users.graphql'

const DriverLoginPage = () => {
  const { logIn, logOut, currentUser } = useAuth()
  const { redirectTo, phone } = useParams()
  const redirectUri = window.location.origin + redirectTo

  const onLogin = useCallback(async () => {
    await logIn({
      authorizationParams: {
        redirect_uri: redirectUri,
        connection: 'sms',
        login_hint: phone,
      },
      appState: {
        targetUrl: redirectUri,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logIn])

  const [beforeLogout] = useMutation(BEFORE_LOGOUT, {
    onCompleted: async () => {
      await logOut({
        logoutParams: {
          returnTo: redirectUri,
        },
      })
    },
  })

  const onLogOut = useCallback(async () => {
    await beforeLogout()
  }, [beforeLogout])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        currentUser &&
        !currentUser.roles.includes('DRIVER') &&
        !currentUser.roles.includes('ADMIN')
      ) {
        onLogOut()
      } else {
        onLogin()
      }
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [onLogin])

  return (
    <>
      <div className="flex min-h-screen flex-col items-center justify-center gap-6">
        <Loader />
        <div className="font-medium text-doubleNickel-gray-700">
          Loading your application...
        </div>
      </div>
    </>
  )
}

export default DriverLoginPage
